'use client';
import Image from 'core/image';
import useScroll from 'hooks/useScroll';
import Link from 'core/link';
import propTypes from 'prop-types';
import hamburgerWhite from 'public/icons/hamburger-white.svg';
import closeWhite from 'public/icons/close-x.svg';
import smartBlack from 'public/icons/smart-black.svg';
import smartWhite from 'public/icons/smart-white.svg';
import { useState, useEffect } from 'react';
import HamburgerMenu from './HamburgerMenu';
import StyledHeader from './StyledHeader';
import arrowRight from 'public/icons/arrow-right.svg';
import ModelsMenu from 'components/Layout/Header/ModelsMenu';
import useIsMobile from '../../../hooks/useIsMobile';

const ClientHeader = ({ headerMenu, siteTitle, models }) => {
  const { prevScrollY: { current: positionY } = {} } = useScroll();
  const [toggleHamburgerMenu, setToggleHamburgerMenu] = useState(false);
  const [isModelsMenuOpen, setIsModelsMenuOpen] = useState(false);
  const [className, setClassName] = useState('');

  const { menu = [] } = headerMenu || {};
  const isMobile = useIsMobile();

  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);

  const handleSubMenuClick = (index) => {
    if (index === openSubMenuIndex) {
      setOpenSubMenuIndex(null);
    } else {
      setOpenSubMenuIndex(index);
    }
  };

  let subMenuItemsJSX = null;

  if (openSubMenuIndex !== null) {
    const selectedItem = menu[openSubMenuIndex];

    if (selectedItem && selectedItem.subItems && selectedItem.subItems.length > 0) {
      subMenuItemsJSX = (
        <div className="sub-menu">
          {selectedItem.subItems.map((subItem, index) => {
            const { label = '', uri = '' } = subItem || {};

            return (
              <div className="sub-menu-item" key={index}>
                <Link href={uri}>{label}</Link>
              </div>
            );
          })}
        </div>
      );
    }
  }

  const menuJSX = menu?.map((menuItem, index) => {
    const { subItems = [], label = '', uri = '' } = menuItem || {};
    const hasSubItems = subItems.length > 0;
    const isSubMenuOpen = index === openSubMenuIndex;
    const menuItemClass = `menu-item ${hasSubItems ? 'with-sub-menu' : ''} ${isSubMenuOpen ? 'sub-menu-open' : ''}`;

    const handleClick = () => {
      if (hasSubItems) {
        handleSubMenuClick(index);
      }
    };

    return (
      <div className={menuItemClass} key={index} onClick={handleClick}>
        <div className="menu-item-label">
          <Link href={uri}>{label}</Link>
        </div>
      </div>
    );
  });

  const handleModelsMenuClick = (e) => {
    e.preventDefault();

    if (toggleHamburgerMenu) {
      setToggleHamburgerMenu(!toggleHamburgerMenu);
      setOpenSubMenuIndex(null);
    }

    setIsModelsMenuOpen(!isModelsMenuOpen);
  };

  const toggleBurgerMenu = () => {
    if (isModelsMenuOpen) {
      setIsModelsMenuOpen(!isModelsMenuOpen);
    }

    setToggleHamburgerMenu(!toggleHamburgerMenu);
  };

  let iconUrl;
  if (openSubMenuIndex !== null) {
    iconUrl = arrowRight;
  } else if (toggleHamburgerMenu) {
    iconUrl = closeWhite;
  } else {
    iconUrl = hamburgerWhite;
  }

  useEffect(() => {
    if (positionY > 0 && (!isMobile || iconUrl === hamburgerWhite)) {
      setClassName('black-icon');
    } else {
      setClassName('');
    }
  }, [positionY, isMobile, iconUrl]);

  const SMART_LOGO_ALT = 'רכבי סמרט - smart מבית כלמוביל';
  const topHeaderMenu = menu?.map((menuItem, index) => {
    const isTopHeaderMenu = menuItem?.classes?.includes('top');

    return isTopHeaderMenu ? (
      <div className="header-item" key={index}>
        <Link href={menuItem.uri}>{menuItem.label}</Link>
      </div>
    ) : null;
  });

  return (
    <StyledHeader positionY={positionY} isShown={toggleHamburgerMenu} isSubMenuShown={openSubMenuIndex !== null}>
      <div className="menu-buttons">
        <HamburgerMenu
          headerMenu={headerMenu}
          icon={iconUrl}
          toggleMenu={toggleHamburgerMenu}
          setToggleMenu={toggleBurgerMenu}
          handleSubMenuClick={handleSubMenuClick}
          isSubMenuShown={openSubMenuIndex !== null}
          subMenuIndex={openSubMenuIndex}
          className={className}
        />

        {!isMobile && (
          <ModelsMenu
            modelsList={models}
            isModelsMenuOpen={isModelsMenuOpen}
            handleModelsMenuClick={handleModelsMenuClick}
          />
        )}
        {!isMobile && topHeaderMenu && <div className="header-menu">{topHeaderMenu}</div>}
      </div>

      <Link href="/" className="smart-logo">
        <Image
          className="smart-icon"
          src={positionY > 0 ? smartBlack : smartWhite}
          alt={SMART_LOGO_ALT}
          fill={false}
        />
      </Link>

      <div className="menu-item"></div>
      <div className="menu-wrap">{menuJSX}</div>
      <div className="sub-menu-wrap">{subMenuItemsJSX}</div>
    </StyledHeader>
  );
};

ClientHeader.propTypes = {
  siteTitle: propTypes.string,
  headerMenu: propTypes.object,
  models: propTypes.array
};

export default ClientHeader;

import Image from 'core/image';
import propTypes from 'prop-types';
import StyledHamburgerMenu from './StyledHamburgerMenu';

const HamburgerMenu = ({
  icon,
  setToggleMenu = {},
  toggleMenu = false,
  isSubMenuShown = false,
  subMenuIndex = null,
  className = '',
  handleSubMenuClick = () => {}
}) => {
  const MENU = 'תפריט';
  const handleOpen = () => {
    isSubMenuShown ? handleSubMenuClick(subMenuIndex) : setToggleMenu();
  };

  return (
    <StyledHamburgerMenu hamburgerOpen={toggleMenu} onClick={handleOpen} isSubMenuShown={isSubMenuShown}>
      <Image className={`hamburger-icon ${className}`} src={icon || ''} alt={MENU} responsive={true} />
    </StyledHamburgerMenu>
  );
};

HamburgerMenu.propTypes = {
  setToggleMenu: propTypes.func.isRequired,
  toggleMenu: propTypes.bool,
  icon: propTypes.object,
  isSubMenuShown: propTypes.bool,
  subMenuIndex: propTypes.number,
  className: propTypes.string,
  handleSubMenuClick: propTypes.func
};

export default HamburgerMenu;

import Image from 'core/image';
import Link from 'core/link';
import useIsMobile from 'hooks/useIsMobile';

const contactValue = (contactOption, phoneMASKU) => {
  const isMobile = useIsMobile();
  const { contactOption: contactType, contactText = '', icon, phoneNumber, link, email } = contactOption || {};
  const iconSize = isMobile ? 28 : 18;
  const iconElement = <Image {...icon} width={iconSize} height={iconSize} responsive={false} />;

  let element = '';
  switch (contactType) {
    case 'phone': {
      const href = `tel:${phoneMASKU || phoneNumber}`;
      const includesAsterisk = typeof contactText === 'string' && contactText?.includes('*');

      element = (
        <Link href={href}>
          {includesAsterisk ? (
            <>
              <span>*</span>
              {contactText.replace('*', '')}
            </>
          ) : (
            contactText
          )}
        </Link>
      );
      break;
    }
    case 'whatsapp':
      element = <Link href={link}>{contactText}</Link>;
      break;
    case 'mail':
      element = <Link href={`mailto:${email}`}>{contactText}</Link>;
      break;
    default:
      element = <span>{contactText}</span>;
      break;
  }

  return (
    <>
      {iconElement}
      {element}
    </>
  );
};

export default contactValue;

'use client';

import propTypes from 'prop-types';
import Link from 'core/link';
import useIsMobile from 'hooks/useIsMobile';
import Whatsapp from 'public/icons/whatsapp.svg';
import Phone from 'public/icons/phone.svg';
import Envelope from 'public/icons/envelope.svg';
import Media from '@/UIComponents/Media';
import StyledStickySideMenu from './StyledStickySideMenu';
import { useEffect, useState } from 'react';
import { isTimeAndDateInOpeningHours } from 'utils/timeUtils';
import useContactUs from 'providers/useContactUs';
import { useRouter } from 'next/navigation';

const ClientStickySideMenu = ({
  contactUsSectionId,
  whatsappLink,
  contactUsHashLink,
  phoneNumber,
  whatsappWorkingHours,
  chatWorkingHours,
  phoneWorkingHours
}) => {
  const router = useRouter();
  const {
    contactUsData: { setContactUsFilled, contactUsFilled, phoneMASKU }
  } = useContactUs() || {};
  const [isCliked, setIsClicked] = useState(false);
  const isMobile = useIsMobile();

  const isWhatsappOpen = isTimeAndDateInOpeningHours(whatsappWorkingHours);
  const isChatOpen = isTimeAndDateInOpeningHours(chatWorkingHours);
  const isPhoneOpen = isTimeAndDateInOpeningHours(phoneWorkingHours);

  const contactUsRedirect = () => {
    setIsClicked(true);
    router.push(contactUsHashLink);
    if (contactUsFilled) {
      setContactUsFilled(false);
    }
  };

  useEffect(() => {
    if (contactUsHashLink && isCliked) {
      const element = document.querySelector(contactUsHashLink);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setIsClicked(false);
    }
  }, [isCliked]);
  return (
    <StyledStickySideMenu>
      <div className="sticky-menu-list">
        {isWhatsappOpen && (
          <Link className={`whatsapp ${isWhatsappOpen ? 'isOpen' : ''}`} href={whatsappLink}>
            <Media src={Whatsapp} responsive alt="" />
          </Link>
        )}
        {(phoneMASKU || phoneNumber) && isPhoneOpen && isMobile && (
          <Link href={`tel:${phoneMASKU || phoneNumber}`}>
            <Media src={Phone} alt="" responsive />
          </Link>
        )}
        {contactUsSectionId && (
          <a className={`contact-us ${isChatOpen ? 'isOpen' : ''}`} onClick={contactUsRedirect}>
            <Media src={Envelope} responsive alt="" />
          </a>
        )}
      </div>
    </StyledStickySideMenu>
  );
};

ClientStickySideMenu.propTypes = {
  contactUsSectionId: propTypes.string,
  whatsappLink: propTypes.string,
  phoneNumber: propTypes.string,
  contactUsHashLink: propTypes.string,
  whatsappWorkingHours: propTypes.array,
  chatWorkingHours: propTypes.array,
  phoneWorkingHours: propTypes.array
};

export default ClientStickySideMenu;
